import React from 'react';
import styled from "styled-components";
import TitleBlock from "../widgets/main/TitleBlock";
import OurValues from "../widgets/main/OurValues";
import Clients from "../widgets/main/Clients";
import Projects from "../widgets/main/Projects";
import {Title} from "../app/textTags";
import {breakpoints} from "../shared/variables";
import Contact from "../widgets/main/Contact";

const Main = () => {

  return (
    <MainWrapper>
      <TitleBlock/>

      <Clients/>
      <Projects/>

      <OurValues/>

      <Contact/>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  margin-bottom: 150px;
  @media ${breakpoints.laptop} {
    gap: 10.71vw;
    margin-bottom: 10.71vw;
  }
  @media ${breakpoints.mobile} {
    gap: 0;
    margin-bottom: 0;
  }
  ${Title} {
    margin-left: 2.86vw;
    margin-bottom: 1.43vw;
    @media ${breakpoints.mobile} {
      margin-bottom: 4.69vw;
      text-align: center;
    }
  }
`

export default Main;