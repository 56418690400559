import React from 'react';
import {Title} from "../../app/textTags";
import styled from "styled-components";
import {breakpoints, COLORS} from "../../shared/variables";

import {Autoplay, FreeMode, Mousewheel, Pagination} from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {CLIENTS_DATA} from "../../features/Data";

const Clients = () => {
  return (
    <Wr>
      <Title>Наши клиенты</Title>
      <SwiperSt
        mousewheel={{
          forceToAxis: true,
        }}
        allowTouchMove={true}
        speed={1000}
        freeMode={{ sticky: true }}
        modules={[Autoplay, Mousewheel, FreeMode, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        spaceBetween={0}
        slidesPerView={'auto'}
        loop
      >
        {CLIENTS_DATA.map((item, index) => (
          <SwiperSlideSt key={`Клиент ${index}`}>
            <Image src={item.image} alt={item.name} />
          </SwiperSlideSt>
        ))}
      </SwiperSt>
    </Wr>
  );
};

const Wr = styled.div`
    
  @media ${breakpoints.mobile} {
    margin-bottom: 15.63vw;
  }
`;

const SwiperSt = styled(Swiper)`
  height: 18vw;
  overflow: visible !important;
  @media ${breakpoints.mobile} {
    height: 28.13vw;
  }
  & .swiper-pagination {
    top: initial;
    bottom: -2.86vw;
    height: auto;
    @media ${breakpoints.mobile} {
      bottom: -8vw;
    }
    & .swiper-pagination-bullet {
      transition: background-color 0.3s;
      height: 1.286vw;
      width: 1.286vw;
      @media ${breakpoints.mobile} {
        height: 2.50vw;
        width: 2.50vw;
      }
      background-color: #d9d9d9;
      opacity: 1;
      margin: 0 1vw;
      &-active {        
        background-color: ${COLORS.red};
      }
    }
  }
`;
const SwiperSlideSt = styled(SwiperSlide)`
  min-height: fit-content;
  width: fit-content !important;
  //min-width: 25% !important;
  border: solid ${COLORS.black};
  border-width:  2px 1px;  
  display: flex !important;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: auto;
  height: 70%;
  margin: 4vw;
  
  -webkit-filter: grayscale(100%); 
  filter: grayscale(100%);
  transition: 0.5s;
  &:hover {
    -webkit-filter: none;
    filter: none !important;
    cursor: pointer;
  }
  @media ${breakpoints.mobile} {
    height: 80%;
  }
`

export default Clients;