import styled from 'styled-components';
import {Link} from 'react-router-dom';
import SvgSelector from "../shared/SvgSelector";
import {breakpoints, COLORS} from "../shared/variables";
import {headerLinks} from "../features/HeaderLinks";
import {SocialLinks} from "./SocialLinks";
import {HeaderBt} from "../app/textTags";
import {Button, useMediaQuery} from "@mui/material";
import {useState} from "react";

const Header = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [open, setOpen] = useState(false)
  return (
    <Head>
      {!isMobile ? (
        <Nav>
          <LogoWr to={'/'}>
            <SvgSelector svg={'logo'}/>
          </LogoWr>
          <Bl1>
            {headerLinks.slice(0, 2).map((link, i) => (
              <Link
                to={link.url}
                key={i}
              >
                <HeaderBt>{link.label}</HeaderBt>
              </Link>
            ))}
          </Bl1>
          <Bl2>
            {headerLinks.slice(2).map((link, i) => (
              <Link
                to={link.url}
                key={i*10}
              >
                <HeaderBt>{link.label}</HeaderBt>
              </Link>
            ))}
            <SocialLinks/>
          </Bl2>
        </Nav>
      ) : (
        <Nav>
          <LogoWr to={'/'}>
            <SvgSelector svg={'logo'}/>
          </LogoWr>
          <Burger onClick={() => setOpen(!open)} open={open}>
            <SvgSelector svg={'burger'}/>
          </Burger>
          <Bl1 open={open}>
            {headerLinks.map((link, i) => (
              <Link
                to={link.url}
                key={i*10}
                onClick={() => setOpen(!open)}
              >
                <HeaderBt>{link.label}</HeaderBt>
              </Link>
            ))}
          </Bl1>
        </Nav>
      )}
    </Head>

  );
};

const Burger = styled.button`
  z-index: 100;
`
const Head = styled.header`
  width: 100%;
  display: flex;  

  background-color: ${COLORS.white};
  max-width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;  
`;
const Nav = styled.nav`
  width: 100%;
  height: 60px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${COLORS.black};
  background-color: ${COLORS.white};
  @media ${breakpoints.laptop} {
    height: 4.29vw;
  }
  @media ${breakpoints.mobile} {
    height: 12.50vw;    
  }
`;
const LogoWr = styled(Link)`
  align-self: center;
  justify-self: start;
  width: auto;
  flex-shrink: 0;
  padding: 0 40px;
  @media ${breakpoints.laptop} {
    padding: 0 2.86vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 3.13vw;
    
  }
  svg {
    width: 270px;
    height: 30px;
    @media ${breakpoints.laptop} {
      width: 100%;
      height: 2.14vw;
    }
    @media ${breakpoints.mobile} {
      width: auto;
      height: 4.69vw;
    }
  }
`
const Bl1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  padding: 0 80px;

  gap: 50px;
  width: 100%;
  border-left: 2px solid ${COLORS.black};
  @media ${breakpoints.laptop} {
    padding: 0 5.71vw;
    gap: 3.57vw;
  }
  @media ${breakpoints.mobile} {
    position: absolute;
    padding: 9.38vw;
    flex-direction: column;
    align-items: start;
    width: 62.50vw;
    right: 0;
    opacity: ${(props) => (props.open ? 1 : 0)};
    border: 2px solid ${COLORS.black};
    background: ${COLORS.white};
    transition: 0.5s;
    right: ${(props) => (props.open ? 0: -100)}%;
  }
  a {
    transition: 0.5s;

    &:hover {
      color: ${COLORS.redActive};
    }
  }
`
const Bl2 = styled(Bl1)`
  padding: 0 2.14vw;
  @media ${breakpoints.laptop} {
    padding: 0 2.14vw;
  }
`
export default Header