import styled from "styled-components";
import {breakpoints} from "../shared/variables";

// ${{ secrets.DOCKERHUB_USERNAME }}
//${{ secrets.DOCKERHUB_TOKEN }}

export const Title = styled.h1`
  font-family: "Actay Wide";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 2.57vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 7.50vw;
  }
`
export const H1 = styled.h2`
  font-family: "Actay Wide";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 2.29vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 6.25vw;
  }
`
export const H2 = styled.h3`
  font-family: "Actay Wide";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 1.71vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 6.25vw;
  }
`
export const H3 = styled.h4`
  font-family: "Actay Wide";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 1.43vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 4.38vw;
  }
`
export const HeaderBt = styled.h5`
  font-family: "Actay Wide";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 1.14vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 6.25vw;
  }
`
export const Text20 = styled.p`
  font-family: "Verdana";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 1.43vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 6.25vw;
  }
`
export const Text = styled.p`
  font-family: 'Verdana';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 1.14vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.75vw;
  }
`
export const Caption = styled.p`
  font-family: "Verdana";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 0.86vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.13vw;
  }
`