import React, {useCallback, useState} from 'react';
import {debounce} from "@mui/material";
import {breakpoints, COLORS} from "../../shared/variables";
import styled, {css, ThemeProvider} from "styled-components";
import SvgSelector from "../../shared/SvgSelector";

const Project = ({data}) => {
  const [hovered, setHovered] = useState(false);

  const setHoveredDelayed = useCallback(debounce(setHovered, 50), []);
  return (
    <ThemeProvider theme={{hover: hovered}}>
      <Section
        onMouseOver={() => setHoveredDelayed(true)}
        onMouseOut={() => setHoveredDelayed(false)}
      >
        <StarBig/>
        {Array.from(Array(5)).map((_, i) => (
          <StarHide key={i} index={i}/>
        ))}
        <Wr>
          <ImgWr>
            <img src={data.photos[0]} alt={data.name}/>
            <img src={data.photos[1]} alt={data.name}/>
          </ImgWr>
          <ContentWr>
            <ProjName>{data.name}</ProjName>
            <Author>{data.author}</Author>
            <Desc>{data.desc}</Desc>
            {/*<MoreButton>*/}
            {/*  <span>Узнать больше</span>*/}
            {/*  <SvgSelector svg={'arrow-more'}/>*/}
            {/*</MoreButton>*/}
          </ContentWr>
        </Wr>
      </Section>
    </ThemeProvider>
  );
};

const StarHide = ({index}) => (
  <StarHideSt index={index}>
    <SvgSelector svg={'red-star'}/>
  </StarHideSt>
);
const StarBig = () => (
  <StarBigSt>
    <SvgSelector svg={'red-star'}/>
  </StarBigSt>
);
const StarHideSt = styled.div`
  pointer-events: none;
  position: absolute;
  transition: transform 0.3s;
  ${({theme: {hover}}) =>
    `opacity: ${hover ? 1 : 0}; 
    transform: scale(${hover ? 1 : 0.5});`}
  width: 120px;
  height: 110px;
  @media ${breakpoints.laptop} {
    width: 8.571vw;
    height: 7.857vw;
  }
  @media ${breakpoints.mobile} {
    transform: scale(0);
  }
  z-index: 2;

  ${({index}) => {
    switch (index) {
      case 0:
        return `
          right: 161px;
          top: -30px;
          @media ${breakpoints.laptop} {
            right: 11.5vw;
            top: -2.143vw;
          }
      `;
      case 1:
        return `
          left: calc(50% - 2.143vw);
          top: -25px;
          @media ${breakpoints.laptop} {
            left: calc(50% - 2.143vw);
            top: -1.786vw;
          }
      `;
      case 2:
        return `
          left: 40vw;
          top: 64%;
          @media ${breakpoints.laptop} {
            left: calc(50% - 7.857vw);
          }
      `;
      case 3:
        return `
          left: calc(50% + 130px);
          bottom: -8%;
          @media ${breakpoints.laptop} {
            left: calc(50% + 9.286vw);
          }
      `;
      case 4:
        return `
          right: -50px;
          bottom: 0;
          @media ${breakpoints.laptop} {
            right: -3.571vw;
          }
      `;
    }
  }}
`;
const StarBigSt = styled.div`  
  pointer-events: none;
  opacity: ${({theme: {hover}}) => hover ? 0 : 1};
  position: absolute;
  top: -7%;
  left: -70px;
  height: 120%;
  transition: 0.5s;
  @media ${breakpoints.laptop} {
    left: -5vw;
  }
  @media ${breakpoints.mobile} {
    left: -20vw;
    opacity: 1;
  }

  & > svg {
    height: 100%;
    width: 277px;
    @media ${breakpoints.laptop} {
      width: 19.786vw;
    }
    @media ${breakpoints.mobile} {
      width: 45vw;
    }
  }
`;

const Section = styled.section`
  border: solid ${COLORS.black};
  border-width: 2px 0;
  position: relative;

  transition: 0.5s;  

  background-color: ${({theme: {hover}}) => hover ? COLORS.black : COLORS.white};
  @media ${breakpoints.mobile} {
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    overflow: hidden;
  }
`;
const Wr = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;
  padding: ${({theme: {hover}}) => hover ? '0px' : '17px 50px'};
  
  transition: 0.5s;
  
  gap: ${({theme: {hover}}) => hover ? 0 : 25}px;
  height: ${({theme: {hover}}) => hover ? 450 : 220}px;
  @media ${breakpoints.laptop} {
    gap: ${({theme: {hover}}) => hover ? 0 : 1.79}vw;
    height: ${({theme: {hover}}) => hover ? 32.14 : 15.71}vw;
    padding: ${({theme: {hover}}) => hover ? '0px' : '1.21vw 3.57vw'};
  }
  @media ${breakpoints.mobile} {
    margin: 0;
    gap: 1.79vw;
    padding: 0 9.38vw;
    height: auto;
    
  }
`;
const ImgWr = styled.div`
  z-index: 1;
  display: grid;
  grid-column: 1;
  grid-row: 1;
  position: relative;
  overflow: hidden;
  flex: none;
  flex-shrink: 0;

  width: ${({theme: {hover}}) => hover ? 630 : 182}px;
  height: auto;

  transition: 0.5s;
  @media ${breakpoints.laptop} {
    width: ${({theme: {hover}}) => hover ? 45 : 13}vw;
  }
  @media ${breakpoints.mobile} {
    width: 37.50vw;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    grid-row: 1;
    grid-column: 1;
    transition: 0.2s;

    &:first-of-type {
      ${({theme: {hover}}) => `opacity: ${hover ? 0 : 1};`};
      @media ${breakpoints.mobile} {
        opacity: 1;
      }
    }

    &:last-of-type {
      ${({theme: {hover}}) => `opacity: ${hover ? 1 : 0}; `};
      height: auto;
      @media ${breakpoints.mobile} {
        opacity: 0;
      }
    }
  }
`;
const ContentWr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  width: 100%;
  height: fit-content ;
  transition: 0.5s;
  text-align: ${({theme: {hover}}) => hover ? 'center' : 'start'};
  @media ${breakpoints.laptop} {
    gap: 0.71vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.13vw 0;
    text-align: start !important;
    margin: 0 !important;
  }
  ${({theme: {hover}}) =>
    css`
      color: ${hover ? COLORS.white : COLORS.black};
      margin: ${hover ? 80 : 0}px;
      @media ${breakpoints.laptop} {
        margin: ${hover ? 5.71 : 0}vw;
      }
      @media ${breakpoints.mobile} {
        color: ${COLORS.black};
      }
    `
  } 
  
}
`
const ProjName = styled.h2`
  font-family: 'Verdana';
  font-size: 32px;
  font-style: normal;
  font-weight: ${({theme: {hover}}) => hover ? 700 : 400};
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 2.29vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.75vw;
    font-weight: 700;
  }
  // text-align: ${({theme: {hover}}) => hover ? 'center' : 'start'};
`
const Author = styled.h3`
  opacity: ${({theme: {hover}}) => hover ? 0 : 1};
  transition: 0;
  font-family: "Verdana";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 1.43vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.13vw;
    opacity: 1;
  }
`
const Desc = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;

  display: -moz-box;
  display: -webkit-box;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  
  font-family: "Verdana";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${breakpoints.laptop} {
    font-size: 1.43vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 2.50vw;
    -webkit-line-clamp: 5;
    text-align: justify;
    line-clamp: 5;
  }
  ${({theme: {hover}}) =>
    css`
        //color: ${hover ? COLORS.white : COLORS.black};
        -webkit-line-clamp: ${hover ? '7' : '2'};
        text-align: ${hover ? 'center' : 'justify'};
        line-clamp: ${hover ? '7' : '2'};
      `
  }
}
`

export default Project;