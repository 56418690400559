import React from 'react';
import {breakpoints, COLORS} from "../../shared/variables";
import styled from "styled-components";
import {Title} from "../../app/textTags";
import Project from "./Project";
import {PROJECTS} from "../../features/Data";
import {Link} from "react-router-dom";

const Projects = () => {
  return (
    <Wr>
      <Title>Проекты</Title>
      <ProjectsWr>
        {PROJECTS.map((project, i) => (
          <Project key={i} data={project} />
        ))}
      </ProjectsWr>
      {/*<More to={'/projects'}>смотреть все проекты</More>*/}
    </Wr>
  );
};

const More = styled(Link)`
  width: 100%;
  display: block;
  text-align: right;
  border-top: 2px solid ${COLORS.black};
  border-bottom: 2px solid ${COLORS.black};
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  padding: 0 120px;
  @media ${breakpoints.laptop} {
    padding: 0 8.571vw;
  }
  transition:
    color 0.3s,
    background-color 0.3s;
  &:hover {
    background-color: ${COLORS.black};
    color: ${COLORS.white};
  }
  &:active {
    color: ${COLORS.grey};
  }
`;
const Wr = styled.div`
  @media ${breakpoints.laptop} {
    
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 15.63vw;    
  }
`;

const ProjectsWr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.14vw;
  margin-bottom: 60px;
  @media ${breakpoints.laptop} {
    gap: 2.143vw;
    margin-bottom: 4.286vw;
  }
`;

export default Projects;