import React from 'react';
import styled, {css} from "styled-components";
import {Caption, H1, H3} from "../../app/textTags";
import {breakpoints, COLORS} from "../../shared/variables";
import emailjs from '@emailjs/browser';
import {useForm} from "react-hook-form";
import patternImg from '../../assets/pattern1.jpg'

import imgBW from '../../assets/main/contactBW.jpg';
import imgColor from '../../assets/main/contactColor.jpg';
import ImageColoring from "../../shared/ImageColoring";

const Contact = () => {

  const {
    handleSubmit,
    register,
    reset,
    formState: { isValid },
  } = useForm();

  const onSubmit = (values, e) => {
    //console.log('values: ', values, e.target);

    emailjs
      .sendForm('service_wjft68a', 'template_os5rh1e', e.target, {
        publicKey: 'Ub0KtqImJsye3q2h6',
      })
      .then(
        () => {
          reset();
          console.log('SUCCESS!');
        },
        error => {
          console.log('FAILED...', error);
        },
      );
  };

  return (
    <ContactWr>
      <Form id="#form-publicart" onSubmit={handleSubmit(onSubmit)}>
        <BlockTitle>
          <H1>Связаться с нами</H1>
        </BlockTitle>
        <BlockName>
          <RedStar>*</RedStar>
          <Input
            type="name"
            placeholder="Как к вам обращаться?"
            {...register('name', {
              required: true,
            })}
          />
        </BlockName>
        <BlockEmail>
          <RedStar>*</RedStar>
          <Input
            type="email"
            placeholder="E-mail"
            {...register('email', {
              required: true,
            })}
          />
        </BlockEmail>
        <BlockTel>
          <RedStar>*</RedStar>
          <Input
            type="tel"
            placeholder="+7(999)999-99-99"
            {...register('tel', {
              required: true,
            })}
          />
        </BlockTel>
        <BlockComment>
          <Comment
            {...register('comment')}
            placeholder="Здесь вы можете описать ваш запрос. Если у вас есть дополнительные материалы (фото, план и прочее), добавьте их ссылкой на Google или Яндекс диск."
          />
        </BlockComment>
        <BlockSubmit>
          <Send disabled={!isValid} type="submit">
            <H3>Отправить</H3>
          </Send>
        </BlockSubmit>
        <BlockDescription>
          <Caption>
            Нажав на кнопку отправить, вы соглашаетесь на обработку персональных данных.
          </Caption>
        </BlockDescription>

        <BlockNull2/>
        <BlockNull/>
      </Form>
      {/*<ImageColoring colorImage={imgColor} bwImage={imgBW} width={39.57} height={35.71} />*/}
      <Image src={patternImg} alt={'Связаься с PublickArt'}/>
    </ContactWr>
  );
};

const placeholder = css`
  font-family: 'Verdana';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  @media ${breakpoints.laptop} {
    font-size: 1.14vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.75vw;
  }
`
const RedStar = styled.span`
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  font-variation-settings: 'slnt' 0;
  color:${COLORS.red};
  font-size: 12px;
  display: inline-block;
  padding-left: 2.86vw;
  @media ${breakpoints.laptop} {
    font-size: 0.86vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.75vw;
  }
`;
const ContactWr = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  
  border: 1px solid ${COLORS.black};
  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
  }
`
const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr repeat(3, 1fr) 2fr 1fr;
  gap: 0;
  grid-template-areas: 
    "TitleBlock Null2"
    "Name Comment"
    "Email Comment"
    "Tel Comment"
    "Null Submit"
    "Null Description";
  @media ${breakpoints.mobile} {
    grid-template-areas: 
      "TitleBlock"
      "Name"
      "Email"
      "Tel"
      "Comment"
      "Submit"
      "Description"
    ;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr repeat(3, 1fr) 2fr 2fr 1fr;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 1px solid ${COLORS.black};
`
const Block = styled.div`
  position: relative;
  border: 1px solid ${COLORS.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  ${placeholder};
  
  &::placeholder {
    ${placeholder};
    
    color: ${COLORS.grey2};
  }
`;
const Comment = styled.textarea`  
  resize: none;
  border: none;
  width: 100%;
  height: 100%;
  color: ${COLORS.black};
  overflow: scroll;  
  outline: none;
  overflow-x: hidden;
  ${placeholder};
  padding-left: 2.86vw;
  padding-top: 1.23vw;
  &::placeholder {
    ${placeholder};
    color: ${COLORS.grey2};
  }
`;
const Send = styled.button`  
  display: flex;
  justify-content: center;
  align-items: center;
  
  background-color: ${COLORS.black};
  color: ${COLORS.grey};
  width: 100%;
  height: 100%;
  transition: 0.5s;
  &:hover {
    background-color: ${COLORS.blackActive};
    color: ${COLORS.grey};
  }  
  @media ${breakpoints.laptop} {
    
  }
  &:disabled {
    pointer-events: none;
    background-color: ${COLORS.grey};
    color: ${COLORS.grey2};
  }
`;

const BlockTitle = styled(Block)`
  grid-area: TitleBlock;
`;
const BlockName = styled(Block)`
  grid-area: Name;
  //height: 60px;
`;
const BlockEmail = styled(Block)`
  grid-area: Email;
`;
const BlockComment = styled(Block)`
  grid-area: Comment;
`;
const BlockTel = styled(Block)`
  grid-area: Tel;
`;
const BlockSubmit = styled(Block)`
  grid-area: Submit;
`;
const BlockDescription = styled(Block)`  
  grid-area: Description;
  padding: 14px 20px;
  @media ${breakpoints.laptop} {
    padding: 1vw 1.43vw;
  }
`;
const BlockNull = styled(Block)`
  grid-area: Null;
  @media ${breakpoints.mobile} {
    display: none;
  }
`;
const BlockNull2  = styled(Block)`
  grid-area: Null2;
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

export default Contact;