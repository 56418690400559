import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import {Text, Title} from "../app/textTags";
import { SVG_COLORING } from "../features/Data";
import img from '../assets/colorizePin.png'
import {breakpoints} from "../shared/variables";
import {useMediaQuery} from "@mui/material";

const PageInProgress = () => {
  const canvasRef = useRef(null);
  const [color, setColor] = useState("#ff3fab"); //#ff3fab
  const statusMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = window.innerWidth / 100 * 90;
    canvas.height = statusMobile ? window.innerWidth / 100 * 70 : window.innerWidth / 100 * 30;

    const svgString = `
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="${canvas.width}"
        height="${canvas.height}"
        viewBox="0 0 ${statusMobile ? 1000 : 1354} ${statusMobile ? 702 : 215}" 
        fill="none"
      >
        ${statusMobile ? SVG_COLORING.mobile[0] : SVG_COLORING.desktop[0]}
      </svg>
    `;

    const DOMURL = window.URL || window.webkitURL || window;
    const img = new Image();
    const svg = new Blob([svgString], { type: 'image/svg+xml' });
    const url = DOMURL.createObjectURL(svg);

    img.onload = function () {
      context.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);
    };

    img.src = url;
    // window.addEventListener('mousemove', draw);
    //
    // return () => {
    //   window.removeEventListener('mousemove', draw);
    // };
  }, []); // Обновляем эффект, когда меняется цвет

  const draw = (e) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x =  statusMobile ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
    const y = statusMobile ? e.touches[0].clientY - rect.top : e.clientY - rect.top;

    const numParticles = 50; // Количество частиц
    const radius = 100; // Радиус эффекта

    for (let i = 0; i < numParticles; i++) {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * radius - 20;

      const particleX = x + Math.cos(angle) * distance;
      const particleY = y + Math.sin(angle) * distance;

      context.fillStyle = color; // цвет
      context.globalCompositeOperation = 'source-atop';
      context.beginPath();
      context.arc(particleX, particleY, Math.random()*2, 0, Math.PI * 2);
      context.fill();
    }
  };

  const handleColorChange = (e) => {
    setColor(e.target.value); // Обновляем цвет при изменении ползунка
  };

  return (
    <Wrapper>
      <Title>Страница в разработке</Title>
      <canvas
        onMouseMove={draw}
        onTouchMove={draw}
        ref={canvasRef}
      />
      <img src={img} alt={''}/>
      <input type="color" value={color} onChange={handleColorChange} />
      {statusMobile && (<Text>Проведите по экрану</Text>)}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 5%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${breakpoints.mobile} {
    padding-bottom: 40vw;
  }
  canvas {
    //border: 1px solid red;
  }
  input {    
    position: absolute;
    top: 0;
    right: 0;
    mix-blend-mode: color-burn;
    -webkit-blend-mode: color-burn;
    width: 200px;
    height: 200px;
    @media ${breakpoints.laptop} {
      width: 12vw;
      height: 12vw;
    } 
    @media ${breakpoints.mobile} {
      width: 40vw;
      height: 40vw;
      top: auto;
      bottom: 0;
      mix-blend-mode: color;
      -webkit-blend-mode: color;
    }
    &:hover {
      cursor: pointer;
    }
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;
    @media ${breakpoints.laptop} {
      width: 12vw;
      height: 12vw;
    }
    @media ${breakpoints.mobile} {
      width: 40vw;
      height: 40vw;
      top: auto;
      bottom: 0;
    }
  }
`


export default PageInProgress;
