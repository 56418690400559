import { createGlobalStyle } from 'styled-components';
import {COLORS, fonts} from "../shared/variables";

export const GlobalStyle = createGlobalStyle`
  ${fonts};
  
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;

    &::selection {
      background-color: black;
      color: ${COLORS.white};
    }

    &::-webkit-scrollbar {
      background: transparent;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background: ${COLORS.black};
    }
  }

  @-moz-document url-prefix() {
    * {
      scrollbar-color: ${COLORS.white} transparent;
      scrollbar-width: thin;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    border: none;
    outline: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
  }

  img, svg {
    max-width: 100%;
    max-height: 100%;
  }

  html {
    scroll-padding-top: 60px;
    scroll-behavior: smooth;
  }

  input {
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  body {
    color: ${COLORS.black};
    background-color: ${COLORS.white};
    line-height: normal;
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
    margin: 0;
  }
`;