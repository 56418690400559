import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import {breakpoints} from "./variables";
import {useMediaQuery} from "@mui/material";

const ImageColoring = ({bwImage, colorImage, width, height}) => {
  const canvasRef = useRef(null);
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [bwImageLoaded, setBwImageLoaded] = useState(false);
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = window.innerWidth / 100 * width;
    canvas.height = isMobile ? window.innerWidth : window.innerWidth / 100 * height;

    // Загрузка изображений



    const img1 = new Image();
    img1.src = bwImage;
    img1.onload = () => {
      if (isMobile) return;
      context.drawImage(img1, 0, 0, canvas.width, canvas.height);
      console.log(bwImage)
      setBwImageLoaded(true); // Установка состояния, когда изображение загружено
    };

    const draw = (e) => {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const numParticles = 100; // Количество частиц
      const radius = 100; // Радиус эффекта

      for (let i = 0; i < numParticles; i++) {
        const angle = Math.random() * Math.PI * 2;
        const distance = Math.random() * radius;

        const particleX = x + Math.cos(angle) * distance;
        const particleY = y + Math.sin(angle) * distance;

        context.globalCompositeOperation = 'destination-out';
        context.beginPath();
        context.arc(particleX, particleY, 1, 0, Math.PI * 2);
        context.fill();
      }
    };

    window.addEventListener('mousemove', draw);

    return () => {
      window.removeEventListener('mousemove', draw);
    };
  }, [bwImage, colorImage, canvasRef, bwImageLoaded]);

  return (
    <ImgWrapper>
      <canvas
        ref={canvasRef}
        style={{
          backgroundImage: `url(${isMobile ? bwImage : colorImage})`,
          backgroundSize: isMobile ? '300%' : 'cover',
          backgroundPosition: isMobile ? 'top center' : 'top left'
        }}
      />
    </ImgWrapper>

  );
};

const ImgWrapper = styled.div`
  position: absolute;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  z-index: -1;

  canvas {
    display: block;
  }
`;

export default ImageColoring;