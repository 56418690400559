import Main from "./pages/Main";
import PageInProgress from "./pages/PageInProgress";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Header from "./widgets/Header";
import Footer from "./widgets/Footer";
import styled from "styled-components";
import {breakpoints} from "./shared/variables";

function App() {
  return (
    <BrowserRouter>
      <PageWr>
        <Routes>
          {/*<Route path="/*" element={<Main />} />*/}
          <Route path="/*" element={<PageInProgress />} />
          <Route path="/coming-soon" element={<PageInProgress />} />

          {/*---Redirect to main---*/}
          <Route path="*" element={<Navigate to={'/'} replace/>}/>
        </Routes>
      </PageWr>
      <Footer />
      <Header />

    </BrowserRouter>
  );
}
const PageWr = styled.main`
  width: inherit;
  overflow-x: clip;
  padding-top: 60px;
  margin: 0;
  @media ${breakpoints.laptop} {
    padding-top: 4.29vw;
  }
  @media ${breakpoints.mobile} {
    padding-top: 12.50vw;
  }
`

export default App;
