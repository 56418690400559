export const headerLinks = [
  { label: 'Проекты', url: '/coming-soon' },
  { label: 'Услуги', url: '/coming-soon' },
  { label: 'О PublicArt', url: '/coming-soon' },
  { label: 'Комьюнити', url: '/coming-soon' },
  { label: 'Контакты', url: '/coming-soon' },
];

export const socialLinks = [
  { svg: 'social-vk', url: 'https://vk.com/publicartspb' },
  { svg: 'social-tg', url: 'https://t.me/publicartspb' },
];