import styled from "styled-components";
import {breakpoints, COLORS} from "../shared/variables";
import {headerLinks} from "../features/HeaderLinks";
import {Link} from "react-router-dom";
import {Text} from "../app/textTags";
import SvgSelector from "../shared/SvgSelector";
import {SocialLinks} from "./SocialLinks";

const Footer = () => {
  return (
    <FooterWr>
      <Null/>
      <FooterBackground>
          <SvgSelector svg={'footer-star'} />
      </FooterBackground>
      <NavContainer>
        <Left>
          <LogoWr to={'/'}>
            <SvgSelector svg={'logo'} />
          </LogoWr>
          {headerLinks.map((link, i) => (
            <Link
              to={link.url}
              key={i}
            >
              <Text>{link.label}</Text>
            </Link>
          ))}
        </Left>

        <Right>
          <Text>publicart.ru</Text>
          <Text>publicart@pushkeen.ru</Text>
          <Text>Россия, г. Санкт-Петербург,<br/> ул. Рубинштейна д.5, офис 11</Text>
          <Text>Официальный хэштег: #publicart_spb</Text>
          <SocialWr>
            <Text>
              Запрещенная в России социальная сеть <br/>
              Inst: <a target={'_blank'} href={'https://www.instagram.com/publicart_spb/?igsh=cGRiYjQ1MTc4dXpo'}>@publicart_spb</a>
            </Text>
            <SocialLinks />
          </SocialWr>

        </Right>
      </NavContainer>
    </FooterWr>
  );
};
const SocialWr = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  text-align: end;
  @media ${breakpoints.laptop} {
    gap: 2.86vw;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column-reverse;
    align-items: end;
  }
`

const FooterWr = styled.footer`
  position: relative;
  display: flex;
  border: solid ${COLORS.black};
  border-width: 2px 0;
`
const Null = styled.div`
  width: 12.86vw;
  height: 100%;
  @media ${breakpoints.mobile} {
    display: none;
  }
`
const LogoWr = styled(Link)`
  align-self: center;
  width: 100%;
  margin-bottom: 1.43vw;
  svg {
    width: 13.50vw;
    height: auto;
  }
  @media ${breakpoints.mobile} {
    svg {
      width: 43.75vw;
    }
    margin-bottom: 18vw;
  }
  
`
const NavContainer = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  justify-content: space-between;
  padding: 2.86vw 5vw;
  border-left: 2px solid ${COLORS.black};
  @media ${breakpoints.mobile} {
    padding: 8.13vw 3.13vw;
  }
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.43vw;
  a { 
    transition: 0.5s;
    &:hover {
      color: ${COLORS.redActive};
    }
  }
  @media ${breakpoints.mobile} {
    gap: 4.69vw;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  //max-width: 300px;
  
  a {
    text-decoration: underline;
    transition: 0.5s;
    &:hover {
      color: ${COLORS.redActive};
    }
  }
  @media ${breakpoints.laptop} {
    gap: 1.43vw;
  }
  @media ${breakpoints.mobile} {
    gap: 4.69vw;
    &:last-child {
      text-align: end;
    }
    ${Text} {
      font-size: 3.13vw;
    }
  }
`

const FooterBackground = styled.div`
  position: absolute;
  z-index: -9999999;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  svg {
    margin-right: 15vw;
    height: 100%;
  }
  @media ${breakpoints.mobile} {
    svg {
      display: none;
    }
  }
`

export default Footer;