import React from 'react';
import ImageColoring from "../../shared/ImageColoring";
import bwImg from '../../assets/main/img1black.png';
import colorImg from '../../assets/main/img1color.jpg';
import styled from "styled-components";
import {breakpoints, COLORS} from "../../shared/variables";
import SvgSelector from "../../shared/SvgSelector";
import {Text} from "../../app/textTags";

const TitleBlock = () => {
  return (
    <Wr>
      <ImageColoring bwImage={bwImg} colorImage={colorImg} width={100} height={59.5} />
      <TitleName>
        <span>PUBLIC</span>
        <span>ART</span>
      </TitleName>
      <Goal>
        <SvgSelector svg={'red-star'} />
        <Content>
          <Text>
            PUBLICART — это креативное агентство, посвященное уличной культуре.
            <br />
            <br />
            Мы объединяем и развиваем сообщество, способствуем сближению художников со зрителями. Наша команда
            занимается организацией, курированием и продвижением инициатив в сфере современного искусства,
            решая как задачи бизнеса, так и культурных организаций.
            <br />
          </Text>
          <SvgSelector svg={'publicart-screen'} />
        </Content>
      </Goal>
    </Wr>
  );
};
const Goal = styled.div`
  overflow: hidden;
  position: absolute;
  bottom: 5.24vw;
  left: 0;
  width: 580px;
  height: 412px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border: solid ${COLORS.black};
  border-width: 2px 2px 2px 0;
  background: ${COLORS.white};
  z-index: 6;
  @media ${breakpoints.laptop} {
    width: 41.43vw;
    height: 29.43vw;
  }
  @media ${breakpoints.mobile} {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    grid-template-columns: 1fr;
    margin-top: -5.3vw;
  }
  & > svg {
    z-index: -1;
    position: absolute;
    bottom: -10%;
    left: -20%;
    height: 90%;
    width: 426px;
    max-width: none;
    @media ${breakpoints.laptop} {
      width: 30.429vw;
    }
    @media ${breakpoints.mobile} {
      width: auto;
      left: -10%;
      bottom: 0;
      transform: scale(2);
      -webkit-transform: scale(2);
      height: 100%;
      
    }
    & > path {
      fill: #ff838b;
    }
  }
`;

const Content = styled.div`
  padding: 60px 25px 30px;
  @media ${breakpoints.laptop} {
    padding: 4.286vw 1.786vw 2.143vw;
  }
  grid-column: 2;
  border-left: 2px solid ${COLORS.black};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  @media ${breakpoints.mobile} {
    padding: 7.81vw;
    border: none;
  }
  & > svg {
    width: 40%;
    height: auto;
    @media ${breakpoints.mobile} {
      width: 48.75vw;
      margin-top: 10vw;
    }
  }
`;
const Wr = styled.div`
  position: relative;
  margin: 0;
`
const TitleName = styled.h1`
  position: relative;
  z-index: 5;
  color: ${COLORS.white};
  line-height: normal;
  font-size: 28vw;
  font-family: "Actay Wide";
  margin: 0;
  pointer-events: none;

  -webkit-touch-callout: none; 
  -webkit-user-select: none;   
  -khtml-user-select: none;    
  -moz-user-select: none;      
  -ms-user-select: none;       
  user-select: none;
  
  @media ${breakpoints.mobile} {
    height: 100vw;
    margin-bottom: 15.63vw;
  }
  & > span {
    display: block;
    &:first-child {
      margin-left: -2%;
    }
    &:last-child {
      margin-top: -6%;
      margin-right: -3%;
      text-align: right;
      @media ${breakpoints.mobile} {
        font-size: 48vw;
      }
    }
  }
`


export default TitleBlock;