import React from 'react';
import styled from "styled-components";
import img from '../../assets/main/publicArtImg.jpg';
import img2 from '../../assets/main/publicArtImg2.jpg'
import {breakpoints, COLORS} from "../../shared/variables";
import {OursValuesData} from "../../features/Data";
import {H3, Text, Title} from "../../app/textTags";
const OurValues = () => {
  return (
    <Wrapper>
      <Title>Наши ценности</Title>
      <Block>
        <img src={img} alt="PublickArt"/>
        <RuleWr>
          {OursValuesData.slice(0,3).map((ourValue, i) => (
            <Rule key={`Наши ценности ${i}`} >
              <H3>{ourValue.name}</H3>
              <Text>{ourValue.text}</Text>
            </Rule>
          ))}
        </RuleWr>
      </Block>
      <Block2>
        <RuleWr>
          {OursValuesData.slice(3).map((ourValue, i) => (
            <Rule key={`Наши ценности 1${i}`}>
              <H3>{ourValue.name}</H3>
              <Text>{ourValue.text}</Text>
            </Rule>
          ))}
        </RuleWr>
        <img src={img2} alt="PublickArt"/>
      </Block2>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
`
const Block = styled.div`
  display: flex;
  border-top: 1px solid ${COLORS.black};
  //display: grid;
  //grid-template-columns: auto auto;  
  //grid-template-rows: 1fr;
  width: 100%;
  img {
    width: 30%;
    height: auto;
    //grid-row-start: 1;
    //grid-column-start: auto;
    //grid-column-end: auto;
    object-fit: cover;
    border: 1px solid ${COLORS.black};
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    img {
      width: 100%;
    }
  }
`
const Block2 = styled(Block)`
  border-top: 0;
  border-bottom: 1px solid ${COLORS.black};
`
const RuleWr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`
const Rule = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.71vw;
  padding:  2.57vw 3.57vw;
  border: 1px solid ${COLORS.black};
  @media ${breakpoints.mobile} {
    padding: 6.25vw;
    gap: 2.50vw;
    ${H3} {
      text-align: center;
    }
    ${Text} {
      text-align: justify;
    }
  }
`

export default OurValues;