export const COLORS = {
  white: '#fff',
  black: '#1A1A18',
  blackActive: '#000000',
  grey: '#D9D9D9',
  grey2: '#A8A8A8',
  red: '#A70709',
  redActive: '#c50411'
}

export const sizes = {
  laptopWidth: '1400px',
  mobileWidth: '450px', //резинка относильно 320px
};

export const breakpoints = {
  laptop: `(max-width: ${sizes.laptopWidth})`,
  mobile: `(max-width: ${sizes.mobileWidth})`,
};

export const fonts = `
  @font-face {
    font-family: 'Actay Wide';
    src: local('ActayWide-Bold'), local('ActayWide Bold'), url('/fonts/actaywide/ActayWide-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Actay Wide';
    src: local('ActayWide-BoldItalic'), local('ActayWide BoldItalic'), url('/fonts/actaywide/ActayWide-BoldItalic.woff2') format('woff2'), url('/fonts/actaywide/ActayWide-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Verdana';
    src: local('Verdana'), url('/fonts/verdana/Verdana.woff2') format('woff2'), url('/fonts/verdana/Verdana.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Verdana';
    src: local('Verdana-Bold'), local('Verdana Bold'), url('/fonts/verdana/Verdana-Bold.woff2') format('woff2'), url('/fonts/verdana/Verdana-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
`