
import styled from 'styled-components';
import {socialLinks} from "../features/HeaderLinks";
import SvgSelector from "../shared/SvgSelector";
import {breakpoints, COLORS} from "../shared/variables";

export const SocialLinks = () => {
  return (
    <SocialLinksWr>
      {socialLinks.map((link, i) => (
        <Link key={i} href={link.url} target="_blank" rel="noopener noreferrer">
          <SvgSelector svg={link.svg} />
        </Link>
      ))}
    </SocialLinksWr>
  );
};

export const SocialLinksWr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media ${breakpoints.laptop} {
    gap: 2.857vw;
  }
  @media ${breakpoints.mobile} {
    margin-top: 0;
  }
`;
const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${COLORS.black};
  width: 40px;
  height: 40px;
  @media ${breakpoints.laptop} {
    width: 2.857vw;
    height: 2.857vw;
  }
  @media ${breakpoints.mobile} {
    width: 12.50vw;
    height: 12.50vw;
  }
  & > svg {
    max-width: 70%;
  }
  transition: background-color 0.3s;
  & path {
    transition: fill 0.3s;
  }
  &:hover {
    background-color: ${COLORS.black};
    & path {
      fill: ${COLORS.white};
    }
  }
  &:active {
    background-color: black;
  }
`;